import cx from 'classnames';
import { Heading, Modal, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { NS_GEO } from 'constants/i18n';
import Flag from 'components/common/Flag';
import { BackButton } from './BackButton';
import { useSuggestCorrectMarket } from './hooks/useSuggestCorrectMarket';
import { MarketSelection } from './MarketSelection';
import {
  SuggestCorrectMarketStep,
  useModalShown,
  useStep,
  useSuggestedMarket,
} from './hooks/useSuggestCorrectMarketStore';
import styles from './SuggestCheckoutModal.module.scss';

const SuggestCheckoutModal = () => {
  const { t } = useTranslation(NS_GEO);
  const modalShown = useModalShown();
  const step = useStep();
  const suggestedMarket = useSuggestedMarket();
  const { closeModal, market } = useSuggestCorrectMarket();

  if (!suggestedMarket) {
    return null;
  }

  return (
    <Modal
      open={modalShown}
      closeButtonShown={false}
      size="s"
      onClose={closeModal}
      footer={<MarketSelection />}
    >
      <Stack crossAxisAlign="center" gap={4} type="vertical">
        <Stack crossAxisAlign="flexStart" gap={2} type="vertical">
          {step === SuggestCorrectMarketStep.LanguageSelection && (
            <BackButton className={styles.backButton} />
          )}
          <Stack crossAxisAlign="center" gap={3} type="vertical">
            <Stack mainAxisAlign="center" type="horizontal">
              <Flag
                countryCode={market.countryCode}
                className={cx(styles.flag, styles.current)}
                rounded
                as={undefined}
                size={undefined}
              />
              <Flag
                countryCode={suggestedMarket.countryCode}
                className={cx(styles.flag, styles.suggested)}
                rounded
                as={undefined}
                size={undefined}
              />
            </Stack>

            <Heading as="h2" variant="s" align="center">
              {t('checkout.title')}
            </Heading>

            <Text variant="m" align="center" textColor="darkSecondary">
              {t('checkout.text')}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

export { SuggestCheckoutModal };

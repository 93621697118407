import { j as d } from "./jsx-runtime.82a9ddf6.js";
import { c as p } from "./cn.2ea4f521.js";
const n = ({
  as: a = "p",
  align: t,
  textColor: e = "dark",
  inline: r,
  children: i,
  className: l,
  ...s
}) => /* @__PURE__ */ d.jsx(
  a,
  {
    className: p(
      "m-0 block",
      e === "dark" && "text-grey-800",
      e === "light" && "text-white",
      e === "darkSecondary" && "text-grey-400",
      e === "highlighted" && "text-blue",
      e === "inherited" && "text-inherit",
      t === "center" && "text-center",
      t === "left" && "text-left",
      t === "right" && "text-right",
      t === "justify" && "text-justify",
      r && "inline",
      l
    ),
    ...s,
    children: i
  }
);
try {
  n.displayName = "TypographyElement", n.__docgenInfo = { description: "", displayName: "TypographyElement", props: { as: { defaultValue: null, description: 'This prop controls what HTML tag to render (**it does not change styling**).\nExample: set `variant="h2"` and `as="span"`, which will render `<span>` element with H2 styles.', name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, align: { defaultValue: null, description: "", name: "align", required: !1, type: { name: '"center" | "right" | "left" | "justify"' } }, textColor: { defaultValue: { value: "dark" }, description: "", name: "textColor", required: !1, type: { name: '"dark" | "light" | "darkSecondary" | "highlighted" | "inherited"' } }, inline: { defaultValue: null, description: "", name: "inline", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  n as T
};

import { create } from 'zustand';
import type { Market } from 'types/market';

enum SuggestCorrectMarketType {
  Checkout = 'checkout',
  Location = 'location',
}

enum SuggestCorrectMarketStep {
  MarketSelection = 'market-selection',
  LanguageSelection = 'language-selection',
}

type SuggestCorrectMarketStore = {
  checkoutUrl?: string;
  forced: boolean;
  modalShown: boolean;
  selectedMarket?: Market;
  step: SuggestCorrectMarketStep;
  suggestedMarket?: Market;
  type: SuggestCorrectMarketType;
  actions: {
    changeStep: (step: SuggestCorrectMarketStep) => void;
    initCheckoutMarketSuggestion: ({
      checkoutUrl,
      forced,
    }: {
      checkoutUrl: string;
      forced?: boolean;
    }) => void;
    selectMarket: (market: Market) => void;
    suggestMarket: (market: Market) => void;
    toggleModalShown: (shown: boolean) => void;
  };
};

const useSuggestCorrectMarketStore = create<SuggestCorrectMarketStore>()((set) => ({
  forced: false,
  modalShown: false,
  step: SuggestCorrectMarketStep.MarketSelection,
  type: SuggestCorrectMarketType.Location,
  actions: {
    toggleModalShown: (shown) => set({ modalShown: shown }),
    selectMarket: (market) => set({ selectedMarket: market }),
    suggestMarket: (market) => set({ suggestedMarket: market }),
    changeStep: (step) => set({ step }),
    initCheckoutMarketSuggestion: ({ checkoutUrl, forced }) =>
      set({
        type: SuggestCorrectMarketType.Checkout,
        modalShown: true,
        checkoutUrl,
        forced,
      }),
  },
}));

const useCheckoutUrl = () => useSuggestCorrectMarketStore((state) => state.checkoutUrl);
const useForced = () => useSuggestCorrectMarketStore((state) => state.forced);
const useModalShown = () => useSuggestCorrectMarketStore((state) => state.modalShown);
const useSelectedMarket = () => useSuggestCorrectMarketStore((state) => state.selectedMarket);
const useStep = () => useSuggestCorrectMarketStore((state) => state.step);
const useSuggestedMarket = () => useSuggestCorrectMarketStore((state) => state.suggestedMarket);
const useType = () => useSuggestCorrectMarketStore((state) => state.type);
const useSuggestCorrectMarketActions = () => useSuggestCorrectMarketStore((state) => state.actions);

export {
  useCheckoutUrl,
  useForced,
  useModalShown,
  useSelectedMarket,
  useStep,
  useSuggestCorrectMarketActions,
  useSuggestedMarket,
  useType,
  useSuggestCorrectMarketStore,
  SuggestCorrectMarketStep,
  SuggestCorrectMarketType,
};

import { j as l } from "./jsx-runtime.82a9ddf6.js";
import { c as f } from "./clsx.0d472180.js";
import { forwardRef as y } from "react";
import { Icon as I } from "../icon.mjs";
import { AnchorOrButton as _ } from "../anchorOrButton.mjs";
import { v as e } from "./styles.b9d17517.js";
const N = {
  xs: "s",
  s: "m",
  m: "m",
  l: "m"
}, o = y(
  ({
    icon: a,
    label: n,
    "aria-label": i = n,
    "aria-labelledby": t,
    variant: s = "yellow",
    size: r = "m",
    iconClassName: m,
    className: u,
    title: c = n,
    ...d
  }, p) => /* @__PURE__ */ l.jsx(
    _,
    {
      className: f(e.root, e[s], e[r], u),
      ref: p,
      "aria-label": t ? void 0 : i,
      "aria-labelledby": t,
      title: c,
      ...d,
      children: /* @__PURE__ */ l.jsx(I, { name: a, size: N[r], className: m })
    }
  )
);
try {
  o.displayName = "IconButton", o.__docgenInfo = { description: "", displayName: "IconButton", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, icon: { defaultValue: null, description: "", name: "icon", required: !0, type: { name: "IconMapMember" } }, label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "string" } }, variant: { defaultValue: { value: "yellow" }, description: "", name: "variant", required: !1, type: { name: "IconButtonVariant" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "IconButtonSize" } }, iconClassName: { defaultValue: null, description: "", name: "iconClassName", required: !1, type: { name: "string" } } } };
} catch {
}
export {
  o as I
};

import { find, map } from 'lodash';
import { Icon, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useId } from 'react';
import { markets } from 'config-ts';
import { getCountryName } from 'services/countries';
import { useMarket } from 'hooks/useMarket';
import Flag from 'components/common/Flag';
import { NS_GEO } from 'constants/i18n';
import { useSuggestCorrectMarket } from './hooks/useSuggestCorrectMarket';
import { useSelectedMarket } from './hooks/useSuggestCorrectMarketStore';
import styles from './CountrySelect.module.scss';

const CountrySelect = () => {
  const { t } = useTranslation(NS_GEO);
  const countrySelectId = useId();
  const { getMarketLanguages } = useMarket();
  const selectedMarket = useSelectedMarket();
  const { confirmMarketSelection, selectMarket } = useSuggestCorrectMarket();

  const changeCountry = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
    const marketSelection = find(
      markets,
      (singleMarket) => !!singleMarket && singleMarket.id === value,
    );

    if (marketSelection) {
      const marketLanguages = getMarketLanguages(marketSelection.countryCode);
      if (marketLanguages.length > 1) {
        selectMarket(marketSelection);
        return;
      }

      const mainLanguage = marketLanguages[0];
      confirmMarketSelection();
      window.location.href = mainLanguage.slug;
    }
  };

  if (!selectedMarket) {
    return null;
  }

  return (
    <Stack gap={0.5}>
      <label htmlFor={countrySelectId}>
        <Text as="span" variant="s">
          {t('country')}
        </Text>
      </label>
      <div className={styles.wrapper}>
        <Flag
          as={undefined}
          className={styles.flag}
          countryCode={selectedMarket.countryCode}
          rounded
          size={24}
        />

        <select
          className={styles.input}
          id={countrySelectId}
          onChange={changeCountry}
          value={selectedMarket.id}
        >
          {map(markets, (market) => (
            <option key={market.id} value={market.id}>
              {getCountryName(market.countryCode)}
            </option>
          ))}
        </select>

        <Icon className={styles.icon} name="chevron-down" />
      </div>
    </Stack>
  );
};

export { CountrySelect };

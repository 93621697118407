import axios from 'axios';
import qs from 'querystringify';
import guid from 'guid';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { authApiConfig } from '../config';
import { createInstance } from './store';
import { getMarket, getLocale } from './site';
import { getAccessToken } from './session';

const PROJECT = 'carvertical';
const STORE_KEY = 'authParams';

const store = createInstance('session');

const api = axios.create({
  baseURL: authApiConfig.baseUrl,
  params: { project: PROJECT },
  timeout: 60 * 1000,
});

const getAuthorizationHeader = () => ({
  Authorization: `Bearer ${getAccessToken()}`,
});

const createTempUser = async (email) => {
  const {
    data: { accessToken },
  } = await api.post(
    '/tempUser',
    {
      username: email,
      market: getMarket().id,
      locale: getLocale(),
      sendsWelcomeEmail: true,
    },
    { params: { project: undefined } },
  );
  return accessToken;
};

const logIn = async (email, password, { captchaResponse }) => {
  const {
    data: { accessToken },
  } = await api.post('/login', { email, password, captchaResponse });
  return accessToken;
};

const logInWithCode = async (code, { codeAccessToken, captchaResponse }) => {
  const {
    data: { accessToken },
  } = await api.post(
    '/login/code',
    { code, captchaResponse },
    {
      headers: { Authorization: `Bearer ${codeAccessToken}` },
    },
  );

  return accessToken;
};

const logInWith = async (provider, { returnTo }) => {
  const state = guid.raw();
  const code = guid.raw();

  store.set(STORE_KEY, { state, code });

  const query = qs.stringify(
    {
      project: PROJECT,
      returnTo,
      state,
      codeVerifier: await sha256(code),
    },
    true,
  );
  window.location.href = `${authApiConfig.baseUrl}/login/${provider}${query}`;
};

const exchangeToken = async ({ state, code }) => {
  const { state: originalState, code: codeVerifier } = store.getOnce(STORE_KEY) || {};

  if (!state || !code || state !== originalState || !codeVerifier) {
    throw new Error('Invalid exchange params');
  }

  const {
    data: { accessToken },
  } = await api.post('/token', { code, codeVerifier });
  return accessToken;
};

const signUp = async (email, password, { captchaResponse }) =>
  api.post(
    '/register',
    {
      email,
      password,
      repeatedPassword: password,
      captchaResponse,
    },
    { params: { locale: getLocale() } },
  );

const acceptTerms = () =>
  api.post(
    '/loginMetadata',
    { action: 'acceptTerms' },
    {
      headers: getAuthorizationHeader(),
    },
  );

const resetPassword = (email, { passwordChangeUrl, captchaResponse }) =>
  api.post(
    '/reset-password',
    { email, captchaResponse },
    { params: { locale: getLocale(), returnTo: passwordChangeUrl } },
  );

const setPassword = (password, token) =>
  api.post('/set-password', { password, repeatedPassword: password }, { params: { token } });

const logOut = () =>
  api.get('/logout', {
    headers: getAuthorizationHeader(),
    data: {},
  });

const impersonate = (userId) =>
  api.post(
    '/impersonate',
    {
      userId,
    },
    { headers: getAuthorizationHeader() },
  );

const unimpersonate = () =>
  api.get('/unimpersonate', {
    headers: getAuthorizationHeader(),
    data: {},
  });

const fetchTokens = async () => {
  const {
    data: { data },
  } = await api.get('/tokens', { headers: getAuthorizationHeader() });
  return data;
};

const createToken = async (description) =>
  api.post('/tokens', { description }, { headers: getAuthorizationHeader() });

const revokeToken = async (id) =>
  api.delete(`/tokens/${id}`, {
    headers: getAuthorizationHeader(),
  });

async function sha256(text) {
  const hash = new Sha256();
  hash.update(text);

  return Array.from(await hash.digest())
    .map((part) => part.toString(16).padStart(2, '0'))
    .join('');
}

export default api;
export {
  createTempUser,
  logIn,
  logInWithCode,
  logInWith,
  exchangeToken,
  signUp,
  acceptTerms,
  resetPassword,
  setPassword,
  logOut,
  impersonate,
  unimpersonate,
  fetchTokens,
  createToken,
  revokeToken,
};

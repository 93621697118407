import { useMode } from 'context/ModeProvider';
import { useRouteData } from 'context/RouteDataProvider';
import store from 'services/store';
import { SuggestLocationModal } from './SuggestLocationModal';
import { PREVENT_SUGGEST_MARKET_NAME } from './hooks/useSuggestCorrectMarket';
import { SuggestCheckoutModal } from './SuggestCheckoutModal';
import {
  SuggestCorrectMarketType,
  useForced,
  useSuggestedMarket,
  useType,
} from './hooks/useSuggestCorrectMarketStore';

type RouteData = {
  preventGeoModal?: boolean;
};

const SuggestCorrectMarket = () => {
  const forced = useForced();
  const type = useType();
  const suggestedMarket = useSuggestedMarket();
  const { isApp } = useMode();
  const { alternativePaths, preventGeoModal } = useRouteData<RouteData>();

  const prevented = !!(
    isApp ||
    (!forced && (preventGeoModal || store.has(PREVENT_SUGGEST_MARKET_NAME)))
  );

  if (prevented || !suggestedMarket || !alternativePaths?.[suggestedMarket.flavors[0].locale]) {
    return null;
  }

  return (
    <>
      {type === SuggestCorrectMarketType.Checkout ? (
        <SuggestCheckoutModal />
      ) : (
        <SuggestLocationModal />
      )}
    </>
  );
};

export { SuggestCorrectMarket, SuggestCorrectMarketType };

import { j as o } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as i } from "react";
import { Icon as p } from "./icon.mjs";
import { O as a } from "./chunks/OptionBase.e155dd16.js";
import { j as m } from "./chunks/styles.b9d17517.js";
import "./chunks/clsx.0d472180.js";
import "./stack.mjs";
import "./chunks/utils.60e8c32c.js";
import "./chunks/lodash.14d7cb6d.js";
import "./grid.mjs";
import "./container.mjs";
import "./sideScrollWrapper.mjs";
import "./heading.mjs";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.80f8cab9.js";
import "./text.mjs";
const t = i((e, r) => /* @__PURE__ */ o.jsx(
  a,
  {
    ref: r,
    type: "checkbox",
    selectionBoxClassName: m.selectionBox,
    selectionIndicator: /* @__PURE__ */ o.jsx(p, { name: "check-thick", size: "s" }),
    ...e
  }
));
try {
  t.displayName = "Checkbox", t.__docgenInfo = { description: "", displayName: "Checkbox", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "ReactNode" } }, status: { defaultValue: null, description: "", name: "status", required: !1, type: { name: '"error"' } } } };
} catch {
}
export {
  t as Checkbox
};

import { Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { NS_GEO } from 'constants/i18n';
import { getCountryName } from 'services/countries';
import { NonModalDialog } from 'components/common/NonModalDialog';
import { useSuggestCorrectMarket } from './hooks/useSuggestCorrectMarket';
import { MarketSelection } from './MarketSelection';
import {
  SuggestCorrectMarketStep,
  useModalShown,
  useStep,
  useSuggestedMarket,
} from './hooks/useSuggestCorrectMarketStore';
import { BackButton } from './BackButton';
import styles from './SuggestLocationModal.module.scss';

const SuggestLocationModal = () => {
  const { t } = useTranslation(NS_GEO);
  const suggestedMarket = useSuggestedMarket();
  const step = useStep();
  const modalShown = useModalShown();
  const { confirmMarketSelection } = useSuggestCorrectMarket();

  if (!suggestedMarket) {
    return null;
  }

  const countryName = getCountryName(suggestedMarket.countryCode);

  return (
    <NonModalDialog closeButtonShown open={modalShown} onClose={confirmMarketSelection}>
      {step === SuggestCorrectMarketStep.LanguageSelection && (
        <BackButton className={styles.backButton} />
      )}

      <Stack crossAxisAlign="stretch" gap={2} type="vertical">
        <Text as="h2" align="center" variant="m+">
          {t('suggestLanguage.title', { country: countryName })}
        </Text>

        <MarketSelection />
      </Stack>
    </NonModalDialog>
  );
};

export { SuggestLocationModal };

import { j as t } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as y } from "./chunks/clsx.0d472180.js";
import { forwardRef as _ } from "react";
import { Icon as o } from "./icon.mjs";
import { AnchorOrButton as I } from "./anchorOrButton.mjs";
import { u as e } from "./chunks/styles.b9d17517.js";
const k = {
  s: "s",
  m: "m",
  l: "m"
}, l = _(
  ({
    variant: r = "black",
    size: s = "s",
    leadingIcon: n,
    trailingIcon: a,
    className: u,
    children: m,
    underline: c = !0,
    ...d
  }, p) => {
    const f = !!(n || a), i = k[s];
    return /* @__PURE__ */ t.jsxs(
      I,
      {
        ...d,
        ref: p,
        className: y(
          e.root,
          e[r],
          e[s],
          f && e.hasIcon,
          c && e.underline,
          u
        ),
        children: [
          n && /* @__PURE__ */ t.jsx(o, { name: n, size: i, className: e.icon }),
          m,
          a && /* @__PURE__ */ t.jsx(o, { name: a, size: i, className: e.icon })
        ]
      }
    );
  }
);
try {
  l.displayName = "LinkButton", l.__docgenInfo = { description: "", displayName: "LinkButton", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, variant: { defaultValue: { value: "black" }, description: "", name: "variant", required: !1, type: { name: "LinkButtonVariant" } }, size: { defaultValue: { value: "s" }, description: "", name: "size", required: !1, type: { name: "LinkButtonSize" } }, leadingIcon: { defaultValue: null, description: "", name: "leadingIcon", required: !1, type: { name: "IconMapMember" } }, trailingIcon: { defaultValue: null, description: "", name: "trailingIcon", required: !1, type: { name: "IconMapMember" } }, underline: { defaultValue: { value: !0 }, description: "", name: "underline", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  l as LinkButton
};

import { useTranslation } from 'next-i18next';
import { IconButton } from 'components/common/IconButton';
import { useSuggestCorrectMarket } from './hooks/useSuggestCorrectMarket';

type BackButtonProps = { className?: string };

const BackButton = ({ className }: BackButtonProps) => {
  const { t } = useTranslation();
  const { initMarketSelection } = useSuggestCorrectMarket();

  return (
    <IconButton
      aria-label={t('general.backAction')}
      className={className}
      icon="arrow-left"
      onClick={initMarketSelection}
    />
  );
};

export { BackButton };
export type { BackButtonProps };

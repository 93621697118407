import { j as p } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as m } from "./chunks/clsx.0d472180.js";
import { u as c } from "./chunks/useTranslatableUILabel.cc84b4da.js";
import { x as e } from "./chunks/styles.b9d17517.js";
import "react";
import "./chunks/TranslatableUIProvider.f5945ac7.js";
const t = ({
  variant: r = "currentColor",
  size: a = "m",
  title: i,
  className: n,
  ...o
}) => {
  const s = c("loading"), l = i || s;
  return /* @__PURE__ */ p.jsx(
    "span",
    {
      role: "status",
      title: l,
      className: m(e.root, e[r], e[a], n),
      ...o
    }
  );
};
try {
  t.displayName = "Spinner", t.__docgenInfo = { description: "", displayName: "Spinner", props: { variant: { defaultValue: { value: "currentColor" }, description: "", name: "variant", required: !1, type: { name: "SpinnerVariant" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "SpinnerSize" } } } };
} catch {
}
export {
  t as Spinner
};

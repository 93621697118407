// CommonJS export is used here for convenience: to also export all keys, not just the whole object.
module.exports = {
  PRECHECK: 1,
  REPORT: 2,
  REPORT_MEXICO: 4,
  REPORT_USA: 6,
  REPORT_RUSSIA: 3001,
  REPORT_BRITAIN: 8001,
  REPORT_AUSTRALIA: 9001,
};

import type { ParseKeys } from 'i18next';
import { mapValues } from 'lodash';
import type {
  Label,
  Logo,
  Action,
  CampaignContent,
  CampaignType,
  CampaignPartnerIdRecord,
} from './types';
import tiriacImage from './assets/images/tiriac-auto.png';
import brcImage from './assets/images/brc.png';
import polizinginiaiImage from './assets/images/polizinginiai-auto.png';
import dealsOnWheelsImage from './assets/images/deals-on-wheels.png';
import autojutaImage from './assets/images/autojuta.png';
import haznaltImage from './assets/images/haznalt-auto.png';
import vehoImage from './assets/images/veho.png';
import spotAWheelImage from './assets/images/spot-a-wheel.png';
import proCarImage from './assets/images/procar.png';
import autoklassImage from './assets/images/autoklass.png';
import driveramaImage from './assets/images/driverama.png';
import rmbImage from './assets/images/rmb.png';
import apanMotorsImage from './assets/images/apan-motors.png';
import agrAutoImage from './assets/images/agr-auto.png';
import carTecumImage from './assets/images/car-tecum.png';
import kupAutoImage from './assets/images/kup-auto.png';
import davoCarsImage from './assets/images/davo-cars.png';
import autoSpotImage from './assets/images/auto-spot.png';
import carTraxImage from './assets/images/cartrax.png';
import buyByeCarImage from './assets/images/buy-bye-car.png';
import billar99Image from './assets/images/billar99.png';
import madisonOperationalImage from './assets/images/madison-operational.png';
import sccImage from './assets/images/scc-logo.png';
import hdiImage from './assets/images/hdi.png';
import tomasiImage from './assets/images/tomasi-auto.png';
import lautoImage from './assets/images/lauto.png';
import deliciaeImage from './assets/images/deliciae.png';
import rsRivieraImage from './assets/images/rs-riviera.png';
import jeanLainHeritageImage from './assets/images/jean-lain-heritage.png';
import bigBlockImportImage from './assets/images/big-block-import.png';
import simplicicarImage from './assets/images/simplicicar.png';
import jbmImage from './assets/images/jbm.png';
import aaaAutoImage from './assets/images/aaa-auto.png';
import importrustImage from './assets/images/importrust.png';
import citadeleImage from './assets/images/citadele.png';
import balciaImage from './assets/images/balcia.png';
import automotoreImage from './assets/images/automotore.png';
import autoZiebaImage from './assets/images/auto-zieba.png';
import motoInwestImage from './assets/images/moto-inwest.png';
import samochodyPlImage from './assets/images/samochody-pl.png';
import rCarsImage from './assets/images/r-cars.png';
import code911Image from './assets/images/code-911.png';
import quattroruoteProfessionalImage from './assets/images/quattroruote-professional.png';
import automontrealGroupImage from './assets/images/automontreal-group.png';
import lomeCollectionImage from './assets/images/lome-collection.png';
import lautoIllustration from './assets/images/lauto-campaign.jpg';
import lautoSuccessIllustration from './assets/images/lauto-campaign-success.jpg';
import tomasiAutoIllustration from './assets/images/tomasi-auto-campaign.jpg';
import tomasiAutoSuccessIllustration from './assets/images/tomasi-auto-campaign-success.jpg';
import simplicicarIllustration from './assets/images/simplicar-campaign.jpg';
import simplicicarSuccessIllustration from './assets/images/simplicicar-campaign-success.jpg';
import jeanlainheritageIllustration from './assets/images/jeanlainheritage-campaign.jpg';
import jeanlainheritageSuccessIllustration from './assets/images/jeanlainheritage-campaign-success.jpg';
import aaaautoIllustration from './assets/images/aaaauto-campaign.jpg';
import aaaautoSuccessIllustration from './assets/images/aaaauto-campaign-success.jpg';
import excelcarIllustration from './assets/images/excelcar-campaign.jpg';
import excelcarSuccessIllustration from './assets/images/excelcar-campaign-success.jpg';
import excelCarImage from './assets/images/excel-car.png';
import concessionariaFioriImage from './assets/images/concessionaria-fiori.png';
import gruppoPiccirilloImage from './assets/images/gruppo-piccirillo.png';
import transakautoImage from './assets/images/transakauto.png';

const HERO_SECTION_ID = 'hero';
const CONTACT_SECTION_ID = 'contact';
const PRICING_SECTION_ID = 'pricing';
const BENEFITS_SECTION_ID = 'benefits';

const SOLUTION_KEYS: ParseKeys<'business'>[] = [
  // @ts-expect-error TS(2322)
  'heroSection.solution.0',
  // @ts-expect-error TS(2322)
  'heroSection.solution.1',
  // @ts-expect-error TS(2322)
  'heroSection.solution.2',
];

const INSURANCE_SOLUTION_KEYS: ParseKeys<'business'>[] = [
  // @ts-expect-error TS(2322)
  'insuranceHeroSection.solution.0',
  // @ts-expect-error TS(2322)
  'insuranceHeroSection.solution.1',
  // @ts-expect-error TS(2322)
  'insuranceHeroSection.solution.2',
];

const LEASING_SOLUTION_KEYS: ParseKeys<'business'>[] = [
  // @ts-expect-error TS(2322)
  'leasingHeroSection.solution.0',
  // @ts-expect-error TS(2322)
  'leasingHeroSection.solution.1',
  // @ts-expect-error TS(2322)
  'leasingHeroSection.solution.2',
];

const PRICING_ACTION: Action = {
  id: PRICING_SECTION_ID,
  labelKey: 'common:general.checkPricingActionFormal',
  variant: 'yellow',
};

const CONTACT_ACTION: Action = {
  id: CONTACT_SECTION_ID,
  labelKey: 'common:general.getInTouchAction',
  variant: 'yellow',
};

const BENEFITS_ACTION: Action = {
  id: BENEFITS_SECTION_ID,
  labelKey: 'common:general.learnMoreAction',
  variant: 'outlined',
};

const BUSINESS_ACTIONS: Action[] = [
  { ...PRICING_ACTION },
  { ...CONTACT_ACTION, variant: 'outlined' },
];
const INSURANCE_ACTIONS: Action[] = [{ ...CONTACT_ACTION }, { ...BENEFITS_ACTION }];
const LEASING_ACTIONS: Action[] = [{ ...CONTACT_ACTION }, { ...BENEFITS_ACTION }];

const MAIN_ACTIVITIES: Label[] = [
  { labelKey: 'contactSection.activities.carDealerTitle' },
  { labelKey: 'contactSection.activities.insuranceTitle' },
  { labelKey: 'contactSection.activities.classifiedTitle' },
  { labelKey: 'contactSection.activities.otherTitle' },
];

const ESTIMATED_REPORT_COUNTS = [
  { label: '10-20' },
  { label: '20-50' },
  { label: '50-100' },
  { label: '100+' },
];

const LOGOS: Record<string, Logo> = {
  veho: {
    id: 'veho',
    src: vehoImage,
    alt: 'Veho logo',
    width: 92,
    height: 26,
  },
  polizinginiai: {
    id: 'polizinginiai',
    src: polizinginiaiImage,
    alt: 'Polizinginiai automobiliai logo',
    width: 88,
    height: 58,
  },
  brc: {
    id: 'brc',
    src: brcImage,
    alt: 'BRC logo',
    width: 76,
    height: 38,
  },
  proCar: {
    id: 'proCar',
    src: proCarImage,
    alt: 'Pro Car logo',
    width: 160,
    height: 19,
  },
  autojuta: {
    id: 'autojuta',
    src: autojutaImage,
    alt: 'Autojuta logo',
    width: 121,
    height: 30,
  },
  dealsOnWheels: {
    id: 'dealsOnWheels',
    src: dealsOnWheelsImage,
    alt: 'Deals on wheels logo',
    width: 103,
    height: 30,
  },
  driverama: {
    id: 'driverama',
    src: driveramaImage,
    alt: 'Driverama logo',
    width: 138,
    height: 32,
  },
  autoklass: {
    id: 'autoklass',
    src: autoklassImage,
    alt: 'Autoklass logo',
    width: 130,
    height: 46,
  },
  apanMotors: {
    id: 'apanMotors',
    src: apanMotorsImage,
    alt: 'Apan Motors logo',
    width: 138,
    height: 17,
  },
  madisonOperational: {
    id: 'madisonOperational',
    src: madisonOperationalImage,
    alt: 'Madison Operational logo',
    width: 114,
    height: 41,
  },
  tiriac: {
    id: 'tiriac',
    src: tiriacImage,
    alt: 'Tiriac Auto logo',
    width: 122,
    height: 80,
  },
  spotAWheel: {
    id: 'spotAWheel',
    src: spotAWheelImage,
    alt: 'Spot a wheel logo',
    width: 141,
    height: 30,
  },
  haznalt: {
    id: 'haznalt',
    src: haznaltImage,
    alt: 'Haznalt Auto logo',
    width: 142,
    height: 20,
  },
  agrAuto: {
    id: 'agrAuto',
    src: agrAutoImage,
    alt: 'Agr Auto logo',
    width: 80,
    height: 32,
  },
  kupAuto: {
    id: 'kupAuto',
    src: kupAutoImage,
    alt: 'KupAuto24 logo',
    width: 71,
    height: 30,
  },
  carTecum: {
    id: 'carTecum',
    src: carTecumImage,
    alt: 'CarTecum logo',
    width: 210,
    height: 6,
  },
  davoCars: {
    id: 'davoCars',
    src: davoCarsImage,
    alt: 'Davo Cars logo',
    width: 113,
    height: 30,
  },
  autoSpot: {
    id: 'autoSpot',
    src: autoSpotImage,
    alt: 'Auto Spot logo',
    width: 120,
    height: 15,
  },
  buyByeCar: {
    id: 'buyByeCar',
    src: buyByeCarImage,
    alt: 'Buy Bye Car logo',
    width: 100,
    height: 65,
  },
  carTrax: {
    id: 'carTrax',
    src: carTraxImage,
    alt: 'CarTrax logo',
    width: 108,
    height: 22,
  },
  billar99: {
    id: 'billar99',
    src: billar99Image,
    alt: 'Billar99 logo',
    width: 70,
    height: 70,
  },
  hdi: {
    id: 'hdi',
    src: hdiImage,
    alt: 'HDI logo',
    width: 76,
    height: 45,
  },
  rmb: {
    id: 'rmb',
    src: rmbImage,
    alt: 'RMB logo',
    width: 150,
    height: 52,
  },
  scc: {
    id: 'scc',
    src: sccImage,
    alt: 'SCC logo',
    width: 160,
    height: 64,
  },
  tomasi: {
    id: 'tomasi',
    src: tomasiImage,
    alt: 'Tomasi Auto logo',
    width: 120,
    height: 30,
  },
  lauto: {
    id: 'lauto',
    src: lautoImage,
    alt: "L'Auto",
    width: 105,
    height: 106,
  },
  deliciae: {
    id: 'deliciae',
    src: deliciaeImage,
    alt: 'deliciae ludis motors logo',
    width: 123,
    height: 45,
  },
  rsRiviera: {
    id: 'rsRiviera',
    src: rsRivieraImage,
    alt: 'RS Riviera logo',
    width: 98,
    height: 74,
  },
  jeanLainHeritage: {
    id: 'jeanLainHeritage',
    src: jeanLainHeritageImage,
    alt: 'Jean Lain Heritage logo',
    width: 140,
    height: 31,
  },
  bigBlockImport: {
    id: 'bigBlockImport',
    src: bigBlockImportImage,
    alt: 'BigBlock Import logo',
    width: 134,
    height: 56,
  },
  simplicicar: {
    id: 'simplicicar',
    src: simplicicarImage,
    alt: 'Simplicicar logo',
    width: 81,
    height: 80,
  },
  jbm: {
    id: 'jbm',
    src: jbmImage,
    alt: 'JB Motors logo',
    width: 81,
    height: 72,
  },
  aaaAuto: {
    id: 'aaaAuto',
    src: aaaAutoImage,
    alt: ' AAA AUTO logo',
    width: 136,
    height: 28,
  },
  importrust: {
    id: 'importrust',
    src: importrustImage,
    alt: ' importrust logo',
    width: 136,
    height: 27,
  },
  citadele: {
    id: 'citadele',
    src: citadeleImage,
    alt: 'Citadele logo',
    width: 142,
    height: 71,
  },
  balcia: {
    id: 'balcia',
    src: balciaImage,
    alt: 'Balcia logo',
    width: 125,
    height: 57,
  },
  automotore: {
    id: 'automotore',
    src: automotoreImage,
    alt: ' Automotore logo',
    width: 124,
    height: 61,
  },
  autoZieba: {
    id: 'autoZieba',
    src: autoZiebaImage,
    alt: ' Auto Zieba logo',
    width: 80,
    height: 36,
  },
  motoInwest: {
    id: 'motoInwest',
    src: motoInwestImage,
    alt: ' Moto Inwest logo',
    width: 125,
    height: 31,
  },
  rCars: {
    id: 'rCars',
    src: rCarsImage,
    alt: ' R Cars logo',
    width: 91,
    height: 27,
  },
  samochodyPl: {
    id: 'samochodyPl',
    src: samochodyPlImage,
    alt: ' Samochody.pl logo',
    width: 139,
    height: 40,
  },
  code911: {
    id: 'code911',
    src: code911Image,
    alt: 'Code 911 - Sport & Prestige logo',
    width: 140,
    height: 57,
  },
  quattroruoteProfessional: {
    id: 'quattroruoteProfessional',
    src: quattroruoteProfessionalImage,
    alt: 'Quattroruote Professional logo',
    width: 136,
    height: 35,
  },
  automontrealGroup: {
    id: 'automontrealGroup',
    src: automontrealGroupImage,
    alt: 'Automontreal Group logo',
    width: 140,
    height: 46,
  },
  lomeCollection: {
    id: 'lomeCollection',
    src: lomeCollectionImage,
    alt: 'Lome Collection logo',
    width: 125,
    height: 30,
  },
  excelCar: {
    id: 'excelCar',
    src: excelCarImage,
    alt: 'Excel Car logo',
    width: 106,
    height: 27,
  },
  concessionariaFiori: {
    id: 'concessionariaFiori',
    src: concessionariaFioriImage,
    alt: 'Concessionaria Fiori logo',
    width: 165,
    height: 28,
  },
  gruppoPiccirillo: {
    id: 'gruppoPiccirillo',
    src: gruppoPiccirilloImage,
    alt: 'Gruppo Piccirillo logo',
    width: 83,
    height: 49,
  },
  transakauto: {
    id: 'transakauto',
    src: transakautoImage,
    alt: 'Transakauto logo',
    width: 135,
    height: 40,
  },
};

const DEFAULT_LOGOS = [
  LOGOS.davoCars,
  LOGOS.veho,
  LOGOS.kupAuto,
  LOGOS.driverama,
  LOGOS.spotAWheel,
  LOGOS.tiriac,
];

const PARTNER_LOGOS: Record<string, Logo[]> = {
  default: DEFAULT_LOGOS,
  lithuania: [
    LOGOS.proCar,
    LOGOS.brc,
    LOGOS.dealsOnWheels,
    LOGOS.veho,
    LOGOS.autojuta,
    LOGOS.polizinginiai,
  ],
  romania: [
    LOGOS.spotAWheel,
    LOGOS.apanMotors,
    LOGOS.rmb,
    LOGOS.madisonOperational,
    LOGOS.autoklass,
    LOGOS.tiriac,
  ],
  hungary: [
    LOGOS.carTecum,
    LOGOS.driverama,
    LOGOS.agrAuto,
    LOGOS.haznalt,
    LOGOS.veho,
    LOGOS.tiriac,
  ],
  poland: [
    LOGOS.aaaAuto,
    LOGOS.automotore,
    LOGOS.autoZieba,
    LOGOS.rCars,
    LOGOS.samochodyPl,
    LOGOS.autoSpot,
    LOGOS.motoInwest,
  ],
  france: [
    LOGOS.simplicicar,
    LOGOS.excelCar,
    LOGOS.jeanLainHeritage,
    LOGOS.transakauto,
    LOGOS.rsRiviera,
    LOGOS.lomeCollection,
    LOGOS.deliciae,
    LOGOS.jbm,
    LOGOS.code911,
    LOGOS.bigBlockImport,
    LOGOS.buyByeCar,
    LOGOS.davoCars,
    LOGOS.veho,
    LOGOS.driverama,
    LOGOS.spotAWheel,
    LOGOS.tiriac,
  ],
  belgium: [
    LOGOS.simplicicar,
    LOGOS.excelCar,
    LOGOS.jeanLainHeritage,
    LOGOS.transakauto,
    LOGOS.rsRiviera,
    LOGOS.lomeCollection,
    LOGOS.deliciae,
    LOGOS.jbm,
    LOGOS.code911,
    LOGOS.bigBlockImport,
    LOGOS.buyByeCar,
    LOGOS.davoCars,
    LOGOS.veho,
    LOGOS.driverama,
    LOGOS.spotAWheel,
    LOGOS.tiriac,
  ],
  germany: [
    LOGOS.kupAuto,
    LOGOS.veho,
    LOGOS.davoCars,
    LOGOS.driverama,
    LOGOS.spotAWheel,
    LOGOS.tiriac,
  ],
  czechia: [
    LOGOS.kupAuto,
    LOGOS.veho,
    LOGOS.davoCars,
    LOGOS.driverama,
    LOGOS.carTrax,
    LOGOS.tiriac,
  ],
  latvia: [LOGOS.billar99, LOGOS.veho, LOGOS.davoCars, LOGOS.driverama, LOGOS.brc, LOGOS.tiriac],
  estonia: [LOGOS.billar99, LOGOS.veho, LOGOS.brc, LOGOS.scc, LOGOS.davoCars, LOGOS.driverama],
  italy: [
    LOGOS.tomasi,
    LOGOS.lauto,
    LOGOS.automontrealGroup,
    LOGOS.concessionariaFiori,
    LOGOS.gruppoPiccirillo,
    LOGOS.quattroruoteProfessional,
    LOGOS.hdi,
  ],
  finland: [
    LOGOS.billar99,
    LOGOS.veho,
    LOGOS.scc,
    LOGOS.davoCars,
    LOGOS.driverama,
    LOGOS.spotAWheel,
  ],
  sweden: [LOGOS.scc, LOGOS.spotAWheel, LOGOS.driverama, LOGOS.kupAuto, LOGOS.veho, LOGOS.davoCars],
  portugal: [LOGOS.importrust, ...DEFAULT_LOGOS],
};

const INSURANCE_PARTNER_LOGOS: Record<string, Logo[]> = mapValues(PARTNER_LOGOS, (logos) => [
  LOGOS.citadele,
  LOGOS.balcia,
  ...logos,
]);

const LEASING_PARTNER_LOGOS: Record<string, Logo[]> = mapValues(PARTNER_LOGOS, (logos) => [
  LOGOS.citadele,
  LOGOS.balcia,
  ...logos,
]);

const CUSTOM_PARTNER_LOGOS: Record<string, Logo[]> = mapValues(PARTNER_LOGOS, (logos) => [
  ...logos.slice(0, 4),
  LOGOS.veho,
  ...logos.slice(4),
]);

const CAMPAIGN_PARTNERS: CampaignPartnerIdRecord<string> = {
  lauto: "L'Auto SPA",
  tomasiauto: 'Tomasi Auto',
  simplicicar: 'Simplicicar',
  jeanlainheritage: 'Jean Lain Heritage',
  aaaauto: 'AAA auto',
  excelcar: 'Excel Car',
};

const FR_CAMPAIGN_TITLE = "Obtenez jusqu'à 20 rapports gratuits";
const FR_CAMPAIGN_SUCCESS_TITLE = 'Merci!';
const FR_CAMPAIGN_SUCCESS_DESCRIPTION =
  'Votre demande a été enregistrée avec succès. Veuillez vérifier votre e-mail pour des rapports gratuits.';
const IT_CAMPAIGN_TITLE = 'Ottieni fino a 20 report gratuiti';
const IT_CAMPAIGN_SUCCESS_TITLE = 'Grazie!';
const IT_CAMPAIGN_SUCCESS_DESCRIPTION =
  'La tua richiesta è stata registrata con successo. Controlla la tua email per il pacchetto di report gratuiti.';
const PL_CAMPAIGN_CTA = 'Otrzymaj do 20 raportów za darmo';
const PL_CAMPAIGN_SUCCESS_TITLE = 'Dziękujemy!';
const PL_CAMPAIGN_SUCCESS_DESCRIPTION =
  'Twoje zgłoszenie zostało pomyślnie zarejestrowane. Sprawdź swoją pocztę, aby otrzymać pakiet darmowych raportów.';

const CAMPAIGN_CONTENT: CampaignType<CampaignContent> = {
  britain: {
    text: {
      title: 'Boost your dealership business',
      description: 'Get up to 20 free reports and witness our product benefits',
      cta: 'Get up to 20 reports for free',
      successTitle: 'Thank You!',
      successDescription:
        'Your request has been successfully registered. Please check your email for the free report bundle.',
    },
  },
  poland: {
    text: {
      title: 'Wzmocnij swój biznes dealerski',
      description: 'Uzyskaj do 20 darmowych raportów i przekonaj się o zaletach naszego produktu.',
      cta: PL_CAMPAIGN_CTA,
      successTitle: PL_CAMPAIGN_SUCCESS_TITLE,
      successDescription: PL_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
  },
  france: {
    text: {
      title: FR_CAMPAIGN_TITLE,
      description:
        'De nombreux concessionnaires français utilisent déjà les rapports carVertical, essayez-les gratuitement en remplissant ce formulaire.',
      cta: FR_CAMPAIGN_TITLE,
      successTitle: FR_CAMPAIGN_SUCCESS_TITLE,
      successDescription: FR_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
  },
  italy: {
    text: {
      title: IT_CAMPAIGN_TITLE,
      description:
        'Molte concessionarie italiane utilizzano già i report di carVertical; provali gratuitamente compilando questo modulo.',
      cta: IT_CAMPAIGN_TITLE,
      successTitle: IT_CAMPAIGN_SUCCESS_TITLE,
      successDescription: IT_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
  },
  lauto: {
    partner: CAMPAIGN_PARTNERS.lauto,
    text: {
      title: IT_CAMPAIGN_TITLE,
      description: "L'Auto SPA ha evitato perdite inaspettate utilizzando carVertical",
      cta: IT_CAMPAIGN_TITLE,
      successTitle: IT_CAMPAIGN_SUCCESS_TITLE,
      successDescription: IT_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: lautoIllustration,
      success: lautoSuccessIllustration,
      badges: [
        {
          className: 'bottom-[5%] right-[15%] sm:right-[30%] md:bottom-[15%]',
          partner: CAMPAIGN_PARTNERS.lauto,
          foundedIn: '1969',
          vehiclesSold: '150 000',
        },
      ],
    },
  },
  tomasiauto: {
    partner: CAMPAIGN_PARTNERS.tomasiauto,
    text: {
      title: IT_CAMPAIGN_TITLE,
      description: 'Tomasi Auto ha aumentato le sue vendite del 21% grazie a carVertical.',
      cta: IT_CAMPAIGN_TITLE,
      successTitle: IT_CAMPAIGN_SUCCESS_TITLE,
      successDescription: IT_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: tomasiAutoIllustration,
      success: tomasiAutoSuccessIllustration,
      className: 'object-center md:object-[50%_80%]',
      successClassName: 'object-[20%_25%] md:object-[30%_50%]',
      badges: [
        {
          className:
            'bottom-[25%] left-[10%] sm:left-[20%] md:bottom-[20%] md:left-[15%] lg:left-[25%]',
          partner: 'Andrea Pirillo',
          followers: '687K',
          platform: 'youtube',
        },
        {
          className: 'bottom-[2%] right-[15%] sm:right-[22%] md:bottom-[5%]',
          partner: CAMPAIGN_PARTNERS.tomasiauto,
          foundedIn: '1997',
          vehiclesSold: '300 000',
        },
      ],
    },
  },
  simplicicar: {
    partner: CAMPAIGN_PARTNERS.simplicicar,
    text: {
      title: FR_CAMPAIGN_TITLE,
      description:
        'Simplicicar a augmenté ses ventes de voitures de 21 % en utilisant carVertical.',
      cta: FR_CAMPAIGN_TITLE,
      successTitle: FR_CAMPAIGN_SUCCESS_TITLE,
      successDescription: FR_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: simplicicarIllustration,
      success: simplicicarSuccessIllustration,
      className: 'md:object-bottom',
      badges: [
        {
          className: 'bottom-[25%] left-[5%] md:bottom-[20%] md:left-[15%]',
          partner: '@Akramjunior',
          followers: '1.1M',
          platform: 'instagram',
        },
        {
          className: 'bottom-1 right-[10%] md:bottom-[5%] md:right-[10%]',
          partner: CAMPAIGN_PARTNERS.simplicicar,
          foundedIn: '2011',
          vehiclesSold: '156 000',
        },
      ],
    },
  },
  jeanlainheritage: {
    partner: CAMPAIGN_PARTNERS.jeanlainheritage,
    text: {
      title: FR_CAMPAIGN_TITLE,
      description:
        "Jean Lain Heritage a économisé des milliers d'euros en coûts imprévus grâce à carVertical.",
      cta: FR_CAMPAIGN_TITLE,
      successTitle: FR_CAMPAIGN_SUCCESS_TITLE,
      successDescription: FR_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: jeanlainheritageIllustration,
      success: jeanlainheritageSuccessIllustration,
      className: 'md:object-bottom',
      successClassName: 'object-[50%_30%] md:object-center',
      badges: [
        {
          className: 'bottom-[5%] right-[10%] sm:right-[30%] md:bottom-[15%] md:right-[30%]',
          partner: CAMPAIGN_PARTNERS.jeanlainheritage,
          foundedIn: '2018',
          vehiclesSold: '1000+',
          luxury: true,
        },
      ],
    },
  },
  aaaauto: {
    partner: CAMPAIGN_PARTNERS.aaaauto,
    text: {
      title: 'Uzyskaj do 20 darmowych raportów',
      description: 'AAA Auto ocenia stan samochodu 2 razy szybciej z carVertical.',
      cta: PL_CAMPAIGN_CTA,
      successTitle: PL_CAMPAIGN_SUCCESS_TITLE,
      successDescription: PL_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: aaaautoIllustration,
      success: aaaautoSuccessIllustration,
      className: 'object-[50%_60%]',
      successClassName: 'object-[50%_60%] md:object-bottom',
      badges: [
        {
          className: 'bottom-[5%] right-[10%] sm:right-[30%] md:bottom-[10%] md:right-[25%]',
          partner: CAMPAIGN_PARTNERS.aaaauto,
          foundedIn: '1992',
          vehiclesSold: '1 600 000',
        },
      ],
    },
  },
  excelcar: {
    partner: CAMPAIGN_PARTNERS.excelcar,
    text: {
      title: FR_CAMPAIGN_TITLE,
      description:
        "EXCEL CAR a accéléré son processus d'achat de véhicules en utilisant carVertical.",
      cta: FR_CAMPAIGN_TITLE,
      successTitle: FR_CAMPAIGN_SUCCESS_TITLE,
      successDescription: FR_CAMPAIGN_SUCCESS_DESCRIPTION,
    },
    illustration: {
      default: excelcarIllustration,
      success: excelcarSuccessIllustration,
      className: 'object-center md:object-[50%_60%]',
      successClassName: 'object-[30%_30%] md:object-[30%_50%]',
      badges: [
        {
          className: 'bottom-[5%] right-[15%] sm:right-[30%] md:bottom-[10%] md:right-[30%]',
          partner: CAMPAIGN_PARTNERS.excelcar,
          foundedIn: '2007',
          vehiclesSold: '3 000',
        },
      ],
    },
  },
};

export {
  PRICING_SECTION_ID,
  MAIN_ACTIVITIES,
  ESTIMATED_REPORT_COUNTS,
  PARTNER_LOGOS,
  HERO_SECTION_ID,
  CONTACT_SECTION_ID,
  BENEFITS_SECTION_ID,
  BUSINESS_ACTIONS,
  INSURANCE_ACTIONS,
  LEASING_ACTIONS,
  SOLUTION_KEYS,
  INSURANCE_SOLUTION_KEYS,
  LEASING_SOLUTION_KEYS,
  INSURANCE_PARTNER_LOGOS,
  LEASING_PARTNER_LOGOS,
  CUSTOM_PARTNER_LOGOS,
  CAMPAIGN_CONTENT,
};

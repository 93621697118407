import cx from 'classnames';
import { type AnchorOrButtonProps, Icon, type IconMapMember } from '@carvertical/ui';
import { forwardRef } from 'react';
import styles from './IconButton.module.scss';

type IconButtonProps = AnchorOrButtonProps & { icon: IconMapMember };

// TODO: Replace with IconButton from design system (@carvertical/ui) when available
const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ className, icon, ...rest }, ref) => (
    <button type="button" className={cx(styles.root, className)} ref={ref} {...rest}>
      <Icon name={icon} size="m" />
    </button>
  ),
);

export { IconButton };
export type { IconButtonProps };

import { j as i } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as W, useRef as k, useId as v, useState as L, useEffect as $ } from "react";
import { c as A } from "./chunks/clsx.0d472180.js";
import "./heading.mjs";
import { Text as V } from "./text.mjs";
import { Stack as r } from "./stack.mjs";
import "./grid.mjs";
import "./container.mjs";
import "./sideScrollWrapper.mjs";
import { Icon as G } from "./icon.mjs";
import { A as a, S as O } from "./chunks/animation-variants.84507dbe.js";
import { f as e } from "./chunks/styles.b9d17517.js";
import { A as P } from "./chunks/index.29668fd9.js";
import { m as d } from "./chunks/motion.24089995.js";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.80f8cab9.js";
import "./chunks/utils.60e8c32c.js";
import "./chunks/lodash.14d7cb6d.js";
const B = {
  [a.Hidden]: { opacity: 0, height: 0 },
  [a.Visible]: { opacity: 1, height: "auto" }
}, N = W(
  ({
    label: o,
    variant: g = "outlined",
    inverted: b = !1,
    message: n,
    type: j,
    id: q,
    size: I = "m",
    disabled: u,
    filled: S = !1,
    readOnly: c,
    status: t,
    fullWidth: T = !1,
    labelHidden: _,
    requiredIndicatorShown: R = !1,
    trailingAddon: m,
    leadingAddon: p,
    className: H,
    ...z
  }, E) => {
    const l = k(null), F = v(), s = q ?? F, f = v(), [M, w] = L(!1), C = (x) => {
      var y;
      if (x.target instanceof HTMLElement && x.target !== l.current)
        return;
      const h = (y = l.current) == null ? void 0 : y.querySelector(`#${CSS.escape(s)}`);
      h instanceof HTMLElement && h.focus();
    };
    return $(() => {
      w(!0);
    }, []), /* @__PURE__ */ i.jsxs(
      "div",
      {
        className: A(
          e.root,
          e[g],
          e[I],
          e[t ?? ""],
          b && e.inverted,
          (u || c) && e.disabled,
          T && e.fullWidth,
          S && e.filled,
          H
        ),
        children: [
          /* @__PURE__ */ i.jsxs(r, { gap: 0.5, children: [
            /* @__PURE__ */ i.jsxs(
              V,
              {
                as: "label",
                variant: "s",
                textColor: "inherited",
                htmlFor: s,
                className: A(e.label, _ && "visuallyHidden"),
                children: [
                  R && /* @__PURE__ */ i.jsx("span", { "aria-hidden": "true", children: "* " }),
                  o
                ]
              }
            ),
            /* @__PURE__ */ i.jsxs(
              r,
              {
                gap: 1,
                type: "horizontal",
                crossAxisAlign: "center",
                className: e.inputWrapper,
                ref: l,
                onClick: C,
                children: [
                  p && /* @__PURE__ */ i.jsx("div", { className: e.addon, children: p }),
                  /* @__PURE__ */ i.jsx(
                    "input",
                    {
                      id: s,
                      ref: E,
                      type: j,
                      disabled: u,
                      readOnly: c,
                      className: e.input,
                      "aria-invalid": t === "error" ? !0 : void 0,
                      "aria-describedby": n ? f : void 0,
                      ...z
                    }
                  ),
                  m && /* @__PURE__ */ i.jsx("div", { className: e.addon, children: m })
                ]
              }
            )
          ] }),
          /* @__PURE__ */ i.jsx(P, { mode: "wait", children: n && /* @__PURE__ */ i.jsx(
            r,
            {
              as: d.div,
              type: "horizontal",
              crossAxisAlign: "center",
              variants: B,
              animate: a.Visible,
              initial: M ? a.Hidden : a.Visible,
              exit: a.Hidden,
              children: /* @__PURE__ */ i.jsxs(r, { type: "horizontal", crossAxisAlign: "center", className: e.message, children: [
                t && /* @__PURE__ */ i.jsx(
                  d.div,
                  {
                    variants: O,
                    transition: {
                      delay: 0.125
                    },
                    children: /* @__PURE__ */ i.jsx(
                      G,
                      {
                        name: t === "error" ? "warning" : "check-circle",
                        size: "s",
                        className: e.icon
                      }
                    )
                  },
                  `${t}-icon`
                ),
                /* @__PURE__ */ i.jsx(
                  V,
                  {
                    variant: "s",
                    textColor: "inherited",
                    id: f,
                    as: d.p,
                    layout: "position",
                    children: n
                  }
                )
              ] })
            }
          ) })
        ]
      }
    );
  }
);
try {
  N.displayName = "TextField", N.__docgenInfo = { description: "", displayName: "TextField", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "string" } }, id: { defaultValue: null, description: "", name: "id", required: !1, type: { name: "string" } }, labelHidden: { defaultValue: null, description: "", name: "labelHidden", required: !1, type: { name: "boolean" } }, fullWidth: { defaultValue: { value: !1 }, description: "", name: "fullWidth", required: !1, type: { name: "boolean" } }, message: { defaultValue: null, description: "", name: "message", required: !1, type: { name: "ReactNode" } }, variant: { defaultValue: { value: "outlined" }, description: "", name: "variant", required: !1, type: { name: "TextFieldVariant" } }, status: { defaultValue: null, description: "", name: "status", required: !1, type: { name: "TextFieldStatus" } }, inverted: { defaultValue: { value: !1 }, description: "", name: "inverted", required: !1, type: { name: "boolean" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "TextFieldSize" } }, filled: { defaultValue: { value: !1 }, description: "", name: "filled", required: !1, type: { name: "boolean" } }, trailingAddon: { defaultValue: null, description: "", name: "trailingAddon", required: !1, type: { name: "ReactNode" } }, leadingAddon: { defaultValue: null, description: "", name: "leadingAddon", required: !1, type: { name: "ReactNode" } }, requiredIndicatorShown: { defaultValue: { value: !1 }, description: "", name: "requiredIndicatorShown", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  N as TextField
};

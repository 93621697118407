import { Stack, Button, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { getCountryName } from 'services/countries';
import { useMarket } from 'hooks/useMarket';
import { useSuggestCorrectMarket } from './hooks/useSuggestCorrectMarket';
import {
  SuggestCorrectMarketType,
  SuggestCorrectMarketStep,
  useSuggestedMarket,
  useCheckoutUrl,
  useType,
  useStep,
  useSelectedMarket,
} from './hooks/useSuggestCorrectMarketStore';
import { CountrySelect } from './CountrySelect';

const MarketSelection = () => {
  const { t } = useTranslation(['geo', 'common']);
  const { confirmMarketSelection, initLanguageSelection, market } = useSuggestCorrectMarket();
  const checkoutUrl = useCheckoutUrl();
  const type = useType();
  const step = useStep();
  const suggestedMarket = useSuggestedMarket();
  const { getMarketLanguages } = useMarket();
  const { selectMarket } = useSuggestCorrectMarket();
  const selectedMarket = useSelectedMarket();

  if (!suggestedMarket) {
    return null;
  }

  const countryName = getCountryName(market.countryCode);
  const suggestedCountryName = getCountryName(suggestedMarket.countryCode);
  const { slug: currentMarketSlug } = getMarketLanguages(market.countryCode, checkoutUrl)[0];

  const switchToMarket = () => {
    selectMarket(suggestedMarket);
    initLanguageSelection();
  };

  const stayInCurrentMarket = () => {
    confirmMarketSelection();
  };

  return (
    <>
      {step === SuggestCorrectMarketStep.MarketSelection && (
        <Stack crossAxisAlign="stretch" gap={2} type="vertical">
          <Button onClick={switchToMarket}>
            {t('switchToCountryAction', { country: suggestedCountryName })}
          </Button>

          <Button
            href={type === SuggestCorrectMarketType.Checkout ? currentMarketSlug : undefined}
            variant="outlined"
            onClick={stayInCurrentMarket}
          >
            {t('stayInCountryAction', { country: countryName })}
          </Button>
        </Stack>
      )}

      {step === SuggestCorrectMarketStep.LanguageSelection && (
        <>
          {selectedMarket ? (
            <Stack
              crossAxisAlign={{
                mobileUp: 'stretch',
              }}
              gap={2}
              type="vertical"
            >
              {type === SuggestCorrectMarketType.Location && <CountrySelect />}

              <Text align="center" variant="m+">
                {t('suggestLanguage.chooseLanguage')}
              </Text>

              {getMarketLanguages(selectedMarket.countryCode, checkoutUrl).map(
                ({ label, slug }) => (
                  <Button
                    href={slug}
                    key={slug}
                    size="s"
                    variant="outlined"
                    onClick={() => confirmMarketSelection()}
                  >
                    {label}
                  </Button>
                ),
              )}
            </Stack>
          ) : (
            <Text>{t('common:general.noResultsLabel')}</Text>
          )}
        </>
      )}
    </>
  );
};

export { MarketSelection };

import { flatMap, keyBy, mapValues } from 'lodash';
import { PaymentMethod } from 'types/payment';
import type { Flavor, Market, MarketId, OptionalMarketIdRecord } from 'types/market';
import { Region } from 'types/regions';
import {
  LP_CROATIA,
  LP_FINLAND,
  LP_FRANCE,
  LP_SLOVAKIA,
  LP_SWEDEN,
  LP_DENMARK,
  LP_HUNGARY,
  LP_UKRAINE,
  LP_BRITAIN,
  LP_LATVIA,
  LP_CZECHIA,
  LP_ITALY,
  LP_POLAND,
  LP_MEXICO,
  VIN_PATTERN,
  LP_AUSTRALIA,
} from './constants/identifiers';
import PRODUCTS from './constants/products';

const INFO_EMAIL = 'info@carvertical.com';
const SUPPORT_EMAIL = 'support@carvertical.com';
const TIER_2_PRICING = 't2';

type InitialMarket = Omit<Market, 'emails' | 'vat'> & { vat?: Market['vat'] };

// TODO: LAUNCH-1490 Add missing features (PPSR, Timeline, Fines, Emissions) to the markets
const initialMarkets: OptionalMarketIdRecord<InitialMarket> = {
  usa: {
    id: 'usa',
    countryCode: 'us',
    countryCodeLong: 'usa',
    region: Region.NorthAmerica,
    flavors: [
      {
        locale: 'en',
        basePath: '/us/',
      },
      {
        locale: 'es-US',
        basePath: '/us/es/',
        i18nLocale: 'es',
      },
    ],
    features: [
      'mileage',
      'damage',
      'makeModelDescription',
      'titleCheck',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'USD',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT_USA,
    },
  },
  europe: {
    id: 'europe',
    countryCode: 'eu',
    countryCodeLong: 'eu',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [
      {
        locale: 'en-EU',
        basePath: '/en/',
        i18nLocale: 'en',
      },
    ],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'EUR',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  britain: {
    id: 'britain',
    countryCode: 'gb',
    countryCodeLong: 'gbr',
    region: Region.Europe,
    flavors: [{ locale: 'en-GB', basePath: '/gb/' }],
    features: [
      'mileage',
      'photos',
      'financialRestrictions',
      'stolenVehicleCheck',
      'emissionTaxes',
      'damage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    marketSwitcherDisabled: true,
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_BRITAIN,
      initial: 'lp',
    },
    currency: 'GBP',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT_BRITAIN,
    },
  },
  australia: {
    id: 'australia',
    countryCode: 'au',
    countryCodeLong: 'aus',
    region: Region.Oceania,
    flavors: [
      {
        locale: 'en-AU',
        basePath: '/au/',
        i18nLocale: 'en',
      },
    ],
    features: [
      'mileage',
      'photos',
      'financialRestrictions',
      'damage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_AUSTRALIA,
      initial: 'vin',
    },
    currency: 'AUD',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT_AUSTRALIA,
    },
  },
  lithuania: {
    id: 'lithuania',
    countryCode: 'lt',
    countryCodeLong: 'ltu',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [
      { locale: 'lt', basePath: '/lt/' },
      {
        locale: 'ru-LT',
        basePath: '/lt/ru/',
        i18nLocale: 'ru',
      },
    ],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'damage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'EUR',
    vat: {
      alreadyIncluded: true,
      initialCountryCode: 'lt',
    },
    checkoutMethods: [
      PaymentMethod.Neopay,
      PaymentMethod.Card,
      PaymentMethod.Paypal,
      PaymentMethod.Crypto,
    ],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  estonia: {
    id: 'estonia',
    countryCode: 'ee',
    countryCodeLong: 'est',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [
      { locale: 'et', basePath: '/et/' },
      {
        locale: 'ru-EE',
        basePath: '/et/ru/',
        i18nLocale: 'ru',
      },
    ],
    identifier: {
      vin: VIN_PATTERN,
    },
    features: ['mileage', 'photos', 'damage', 'makeModelDescription', 'naturalDisasters', 'safety'],
    currency: 'EUR',
    checkoutMethods: [
      PaymentMethod.Neopay,
      PaymentMethod.Card,
      PaymentMethod.Paypal,
      PaymentMethod.Crypto,
    ],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  latvia: {
    id: 'latvia',
    countryCode: 'lv',
    countryCodeLong: 'lva',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [
      { locale: 'lv', basePath: '/lv/' },
      {
        locale: 'ru-LV',
        basePath: '/lv/ru/',
        i18nLocale: 'ru',
      },
    ],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'damage',
      'usage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_LATVIA,
      initial: 'vin',
    },
    currency: 'EUR',
    checkoutMethods: [
      PaymentMethod.Neopay,
      PaymentMethod.Card,
      PaymentMethod.Paypal,
      PaymentMethod.Crypto,
    ],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  poland: {
    id: 'poland',
    countryCode: 'pl',
    countryCodeLong: 'pol',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'pl', basePath: '/pl/' }],
    features: [
      'mileage',
      'photos',
      'damage',
      'valuation',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_POLAND,
      initial: 'lp',
    },
    currency: 'PLN',
    checkoutMethods: [
      PaymentMethod.Tpay,
      PaymentMethod.Card,
      PaymentMethod.Paypal,
      PaymentMethod.Crypto,
    ],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  romania: {
    id: 'romania',
    countryCode: 'ro',
    countryCodeLong: 'rou',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'ro', basePath: '/ro/' }],
    features: [
      'mileage',
      'photos',
      'damage',
      'usage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'RON',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  hungary: {
    id: 'hungary',
    countryCode: 'hu',
    countryCodeLong: 'hun',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'hu', basePath: '/hu/' }],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'damage',
      'usage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_HUNGARY,
      initial: 'vin',
    },
    currency: 'HUF',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  france: {
    id: 'france',
    countryCode: 'fr',
    countryCodeLong: 'fra',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'fr', basePath: '/fr/' }],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'damage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_FRANCE,
      initial: 'vin',
    },
    currency: 'EUR',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  ukraine: {
    id: 'ukraine',
    countryCode: 'ua',
    countryCodeLong: 'ukr',
    region: Region.Europe,
    flavors: [
      { locale: 'uk', basePath: '/ua/' },
      {
        locale: 'ru-UA',
        basePath: '/ua/ru/',
        i18nLocale: 'ru',
      },
    ],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'damage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_UKRAINE,
      initial: 'vin',
    },
    currency: 'USD',
    allowedCurrencyOverrides: ['UAH'],
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  sweden: {
    id: 'sweden',
    countryCode: 'se',
    countryCodeLong: 'swe',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'sv', basePath: '/se/' }],
    features: [
      'mileage',
      'photos',
      'registrationRestrictions',
      'stolenVehicleCheck',
      'usage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_SWEDEN,
      initial: 'vin',
    },
    currency: 'SEK',
    pricing: TIER_2_PRICING,
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  belgium: {
    id: 'belgium',
    countryCode: 'be',
    countryCodeLong: 'bel',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [
      {
        locale: 'nl-BE',
        basePath: '/be/',
        i18nLocale: 'nl',
      },
      {
        locale: 'fr-BE',
        basePath: '/be/fr/',
        i18nLocale: 'fr',
      },
    ],
    features: ['mileage', 'photos', 'damage', 'makeModelDescription', 'naturalDisasters', 'safety'],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'EUR',
    pricing: TIER_2_PRICING,
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  czechia: {
    id: 'czechia',
    countryCode: 'cz',
    countryCodeLong: 'cze',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'cs', basePath: '/cz/' }],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'damage',
      'valuation',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_CZECHIA,
      initial: 'vin',
    },
    currency: 'CZK',
    vat: {
      shortcutsDisabled: true,
    },
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
    exclude: ['odometerBrands', 'titleBrands', 'vehicleUsage', 'taxi'],
  },
  croatia: {
    id: 'croatia',
    countryCode: 'hr',
    countryCodeLong: 'hrv',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'hr', basePath: '/hr/' }],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'damage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_CROATIA,
      initial: 'vin',
    },
    currency: 'EUR',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  bulgaria: {
    id: 'bulgaria',
    countryCode: 'bg',
    countryCodeLong: 'bgr',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'bg', basePath: '/bg/' }],
    features: ['mileage', 'photos', 'damage', 'makeModelDescription', 'naturalDisasters', 'safety'],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'BGN',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  slovakia: {
    id: 'slovakia',
    countryCode: 'sk',
    countryCodeLong: 'svk',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'sk', basePath: '/sk/' }],
    features: [
      'mileage',
      'photos',
      'financialRestrictions',
      'stolenVehicleCheck',
      'damage',
      'valuation',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_SLOVAKIA,
      initial: 'vin',
    },
    currency: 'EUR',
    checkoutMethods: [
      PaymentMethod.Neopay,
      PaymentMethod.Card,
      PaymentMethod.Paypal,
      PaymentMethod.Crypto,
    ],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
    exclude: ['odometerBrands', 'titleBrands', 'taxi'],
    include: ['activityChecks.outstandingFinance'],
  },
  serbia: {
    id: 'serbia',
    countryCode: 'rs',
    countryCodeLong: 'srb',
    region: Region.Europe,
    flavors: [{ locale: 'sr', basePath: '/rs/' }],
    features: ['mileage', 'photos', 'damage', 'makeModelDescription', 'naturalDisasters', 'safety'],

    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'RSD',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  finland: {
    id: 'finland',
    countryCode: 'fi',
    countryCodeLong: 'fin',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [
      { locale: 'fi', basePath: '/fi/' },
      {
        locale: 'sv-FI',
        basePath: '/fi/sv/',
        i18nLocale: 'sv',
      },
    ],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'usage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_FINLAND,
      initial: 'vin',
    },
    currency: 'EUR',
    pricing: TIER_2_PRICING,
    checkoutMethods: [
      PaymentMethod.Neopay,
      PaymentMethod.Card,
      PaymentMethod.Paypal,
      PaymentMethod.Crypto,
    ],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  slovenia: {
    id: 'slovenia',
    countryCode: 'si',
    countryCodeLong: 'svn',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'sl', basePath: '/si/' }],
    features: ['mileage', 'photos', 'damage', 'makeModelDescription', 'naturalDisasters', 'safety'],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'EUR',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  germany: {
    id: 'germany',
    countryCode: 'de',
    countryCodeLong: 'deu',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'de', basePath: '/de/' }],
    features: [
      'mileage',
      'photos',
      'damage',
      'valuation',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'EUR',
    vat: {
      alreadyIncluded: true,
      initialCountryCode: 'de',
    },
    checkoutMethods: [
      PaymentMethod.Sofort,
      PaymentMethod.Neopay,
      PaymentMethod.Card,
      PaymentMethod.Paypal,
      PaymentMethod.Crypto,
    ],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  italy: {
    id: 'italy',
    countryCode: 'it',
    countryCodeLong: 'ita',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'it', basePath: '/it/' }],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'damage',
      'valuation',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_ITALY,
      initial: 'lp',
    },
    currency: 'EUR',
    pricing: TIER_2_PRICING,
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  switzerland: {
    id: 'switzerland',
    countryCode: 'ch',
    countryCodeLong: 'che',
    region: Region.Europe,
    flavors: [
      {
        locale: 'de-CH',
        basePath: '/ch/',
        i18nLocale: 'de',
      },
      {
        locale: 'fr-CH',
        basePath: '/ch/fr/',
        i18nLocale: 'fr',
      },
      {
        locale: 'it-CH',
        basePath: '/ch/it/',
        i18nLocale: 'it',
      },
    ],
    features: ['mileage', 'photos', 'damage', 'makeModelDescription', 'naturalDisasters', 'safety'],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'EUR',
    pricing: TIER_2_PRICING,
    checkoutMethods: [
      PaymentMethod.Card,
      PaymentMethod.Sofort,
      PaymentMethod.Paypal,
      PaymentMethod.Crypto,
    ],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  denmark: {
    id: 'denmark',
    countryCode: 'dk',
    countryCodeLong: 'dnk',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'da', basePath: '/dk/' }],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'usage',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_DENMARK,
      initial: 'vin',
    },
    currency: 'DKK',
    pricing: TIER_2_PRICING,
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  mexico: {
    id: 'mexico',
    countryCode: 'mx',
    countryCodeLong: 'mex',
    region: Region.NorthAmerica,
    flavors: [{ locale: 'es-MX', basePath: '/mx/' }],
    features: ['mileage', 'photos', 'damage', 'makeModelDescription', 'naturalDisasters', 'safety'],
    identifier: {
      vin: VIN_PATTERN,
      lp: LP_MEXICO,
      initial: 'lp',
    },
    currency: 'MXN',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT_MEXICO,
    },
  },
  spain: {
    id: 'spain',
    countryCode: 'es',
    countryCodeLong: 'esp',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'es', basePath: '/es/' }],
    features: ['mileage', 'photos', 'makeModelDescription', 'naturalDisasters', 'safety'],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'EUR',
    checkoutMethods: [
      PaymentMethod.Neopay,
      PaymentMethod.Card,
      PaymentMethod.Paypal,
      PaymentMethod.Crypto,
    ],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  portugal: {
    id: 'portugal',
    countryCode: 'pt',
    countryCodeLong: 'prt',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'pt', basePath: '/pt/' }],
    features: [
      'mileage',
      'photos',
      'stolenVehicleCheck',
      'makeModelDescription',
      'naturalDisasters',
      'safety',
    ],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'EUR',
    vat: {
      alreadyIncluded: true,
      initialCountryCode: 'pt',
    },
    checkoutMethods: [
      PaymentMethod.MBWay,
      PaymentMethod.Card,
      PaymentMethod.Paypal,
      PaymentMethod.Crypto,
    ],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
  greece: {
    id: 'greece',
    countryCode: 'gr',
    countryCodeLong: 'grc',
    isEuropeanUnion: true,
    region: Region.Europe,
    flavors: [{ locale: 'el-GR', basePath: '/gr/' }],
    features: ['mileage', 'photos', 'makeModelDescription', 'naturalDisasters', 'safety'],
    identifier: {
      vin: VIN_PATTERN,
    },
    currency: 'EUR',
    checkoutMethods: [PaymentMethod.Card, PaymentMethod.Paypal, PaymentMethod.Crypto],
    products: {
      precheck: PRODUCTS.PRECHECK,
      report: PRODUCTS.REPORT,
    },
  },
};

const markets = Object.fromEntries(
  Object.entries(initialMarkets).map(([key, value]) => {
    const market = value as Market;
    return [
      key,
      {
        ...market,
        vat: {
          ...market.vat,
          initialCountryCode: market.countryCode !== 'eu' ? market.countryCode : 'us',
          alreadyIncluded: market.vat?.alreadyIncluded || !market.isEuropeanUnion,
        },
        emails: {
          info: INFO_EMAIL,
          support: SUPPORT_EMAIL.replace('@', `+${market.id}@`),
        },
      },
    ];
  }),
) as Record<MarketId, Market>;

const flavors = flatMap<Record<MarketId, Market>, Flavor>(markets, (market) => market.flavors);
const basePathsByLocale = mapValues(keyBy(flavors, 'locale'), 'basePath');
const homeCountryCode = 'lt';
const LOCALES = Object.keys(basePathsByLocale);

export { homeCountryCode, markets, basePathsByLocale, LOCALES };

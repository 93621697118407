import qs from 'querystringify';
import parseUrl from 'url-parse';
import { isEmpty, find, sortBy } from 'lodash';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { getLanguage } from 'utils/locale';
import { basePathsByLocale, markets } from 'config-ts';
import { normalizeCountryCode } from 'services/countries';
import { useRouteData } from 'context/RouteDataProvider';
import type { Flavor } from 'types/market';

type MarketLanguage = {
  active: boolean;
  label: string;
  slug: string;
};

const LANGUAGE: Record<Flavor['locale'], string> = {
  en: 'English',
  lt: 'Lietuvių',
  et: 'Eesti',
  lv: 'Latviešu',
  pl: 'Polski',
  ro: 'Română',
  hu: 'Magyar',
  fr: 'Français',
  uk: 'Українська',
  ru: 'Русский',
  sv: 'Svenska',
  nl: 'Nederlands',
  cs: 'Český',
  hr: 'Hrvatski',
  bg: 'Български',
  sk: 'Slovenský',
  sr: 'Српски',
  fi: 'Suomi',
  es: 'Español',
  sl: 'Slovenski',
  de: 'Deutsch',
  it: 'Italiano',
  da: 'Dansk',
  pt: 'Português',
  el: 'Ελληνικά',
  ar: 'العربية',
};

const LANGUAGE_PARAM = 'l';

const getSearchParams = (pathname: string) => {
  const searchParams = parseUrl(pathname, true).query;

  delete searchParams[LANGUAGE_PARAM];

  if (isEmpty(searchParams)) {
    return '';
  }

  return `?${qs.stringify(searchParams)}`;
};

const getMarketLanguageFlavors = (countryCode: string) => {
  const normalizedCountryCode = normalizeCountryCode(countryCode);

  return find(markets, { countryCode: normalizedCountryCode })?.flavors || [];
};

const useMarket = () => {
  const { alternativePaths = {}, locale: currentMarketLocale } = useRouteData();
  const { asPath: pathname } = useRouter();

  const getMarketLanguages = useCallback(
    (countryCode: string, pathOverride?: string): MarketLanguage[] => {
      const availableFlavors = getMarketLanguageFlavors(countryCode);

      const getSlug = (locale: string, basePath: string) => {
        if (!alternativePaths?.[locale]) {
          return basePathsByLocale[locale];
        }

        if (pathOverride) {
          return `${basePath}${pathOverride}`;
        }

        return (alternativePaths[locale] || basePath) + getSearchParams(pathname);
      };

      const languages = availableFlavors.map(({ basePath, locale }) => ({
        label: LANGUAGE[getLanguage(locale)],
        slug: getSlug(locale, basePath),
        active: currentMarketLocale === locale,
      }));

      return sortBy(languages, ({ active }) => !active);
    },
    [alternativePaths, currentMarketLocale, pathname],
  );

  return { getMarketLanguages };
};

export { useMarket, LANGUAGE };
export type { MarketLanguage };
